<template>
    <div>
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <h2 class="content-header-title float-start mb-0">
              Blog
            </h2>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-2">
          <div class="col-12">
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb float-end">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">Blog</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <div class="col-xl-12 col-md-12 col-12">
              <div class="card card-congratulation-medal">
                <div class="card-body">
                  <div class="row mb-1">
                    <div class="d-flex col-12">
                      <div class="float-start col-6">
                        <div class="input-group input-group-merge w-50">
                          <span class="input-group-text" id="basic-addon-search2"
                            ><vue-feather type="search"></vue-feather></span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search..."
                            aria-label="Search..."
                            aria-describedby="basic-addon-search2"
                            v-model="search"
                            @keyup="searchData"
                          />
                        </div>
                      </div>
                      <!-- data-bs-toggle="modal"
                      data-bs-target="#modals-slide-in" -->
                      <div class="float-end col-6">
                        <button
                          type="button"
                          class="btn btn-gradient-primary float-end"
                          @click="add"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <section id="ajax-datatable">
                    <div class="row">
                      <div class="col-12">
                        <div class="card">
                          <div class="card-datatable">
                            <table
                              class="dt-column-search table table-responsive"
                            >
                              <thead>
                                <tr>
                                  <template v-for="head in header" :key="head">
                                    <th>{{ head }}</th>
                                  </template>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-show="list.length > 0" v-for="(li, index) in list" :key="li.id">
                                  <td>{{ index + paginationData.from }}</td>
                                  <td><img class="avatar " :src="li.image" alt="Image" height="55" width="55" /></td>
                                  <td>{{ li.title }}</td>
                                  <!-- <td><p v-html="li.desc"></p></td> -->
                                  <td>{{ li.created_at }}</td>
                                  <td>{{ li.updated_at }}</td>
                                  <td class="d-flex">
                                    <div
                                      class="d-flex justify-content-start align-items-center mb-75"
                                    >
                                      <span class="me-75">
                                        <div
                                          class="avatar bg-light-primary rounded"
                                          @click="edit(li.id)"
                                        >
                                          <div class="avatar-content">
                                            <vue-feather type="edit" size="15"></vue-feather>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                    <div
                                      class="d-flex justify-content-start align-items-center mb-75"
                                    >
                                      <span class="me-75">
                                        <div
                                          class="avatar bg-light-danger rounded"
                                          @click="deleteDes(li.id)"
                                        >
                                          <div class="avatar-content">
                                            <vue-feather type="trash-2" size="15"></vue-feather>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr v-show="list.length === 0">
                                  <td colspan="7" class="text-center">No Data Found</td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <template v-for="head in header" :key="head">
                                    <th>{{ head }}</th>
                                  </template>
                                </tr>
                              </tfoot>
                            </table>
                            <div class="row d-flex">
                              <div class="col-12 d-flex">
                                <div class="col-6 showing_entry">
                                  Showing {{ paginationData.from }} to
                                  {{ paginationData.to }} of
                                  {{ paginationData.total }} entries
                                </div>
                                <div class="col-6">
                                  <Pagination
                                    :data="paginationData"
                                    @pagination-change-page="getResults"
                                    show-disabled="true"
                                    limit="3"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <teleport to="#modal">
      <div class="modal modal-slide-in new-user-modal fade" id="modals-slide-in" :class="isShow" :style="{display: isDisplay}">
        <div class="modal-dialog">
          <form
            class="add-new-user modal-content pt-0"
            @submit.prevent="submitForm"
            @keydown="form.onKeydown($event)"
            enctype="multipart/form-data"
          >
            <input type="hidden" name="id" :v-modal="form.id" />
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="[isShow='', isDisplay='none']"
            >
              ×
            </button>
            <div class="modal-header mb-1">
              <h4 class="modal-title" id="exampleModalLabel">
                {{ modalName }} Blog
              </h4>
            </div>
            <div class="modal-body flex-grow-1">
  
              <div class="mb-1">
                <label class="form-label" for="basic-addon-name">Title</label>
                <input
                  type="text" class="form-control" name="title" v-model="form.title" placeholder="Title" @keyup="
                    form.title.length > 0
                      ? [(titleError = 'none'), (this.disabled = false)]
                      : (titleError = 'block')
                  "/>
                <div class="invalid-feedback fw-bold fst-italic" :style="{display: titleError}" > Please enter Title. </div>
              </div>
              <div class="row mb-1">
                <div class="col-10">
                    <label class="form-label" for="basic-addon-name">Image</label>
                    <input type="file" ref="file" class="form-control" name="image" placeholder="image" @change="uploadImage" />
                </div>
                <div class="col-2">
                  <div id="user-avatar-section">
                    <div id="d-flex justify-content-start">
                      <img v-if="url" :src="url" class="img-fluid rounded" height="104" width="104"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-1">
                <label class="form-label" for="basic-addon-name">Description</label>
                  <div style="min-height:200px;" ref="mainContentEditor"></div>
                  <div class="invalid-feedback fw-bold fst-italic" :style="{display: titleError}" > Please enter Description. </div>
              </div>
              <button
                type="submit"
                class="btn btn-primary me-1 data-submit"
                :disabled="disabled"
              >
                Submit
              </button>
              <button
                type="reset"
                class="btn btn-outline-secondary"
                :disabled="disabled"
                data-bs-dismiss="modal"
                @click="[isShow='', isDisplay='none']"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </teleport>
  </template>
  
  <script>
  import axios from "axios";
  import LaravelVuePagination from "laravel-vue-pagination";
  import Form from 'vform';
  import { ref } from 'vue'
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import Quill from "quill";

  export default {
    name: "Blog",
    components: {
      Pagination: LaravelVuePagination,
    },
    data() {
      return {
        form: new Form({
          id: null,
          title: "",
          desc: "",
          image: "",
        }),
        mainContentEditor: null,
        mainContentEditorValue: '',
        content: ref({}),
        editor: null,
        url: null,
        response: "",
        disabled: false,
        header: [],
        list: [],
        modalName: "Add",
        paginationData: {},
        search: null,
        titleError: "none",
        descError: "none",
        imageError: "none",
        isShow: "",
        isDisplay: "none",
        token: localStorage.getItem('auth_token'),
      };
    },
    async mounted() {
      this.header = [
        "Sr. no.",
        "Image",
        "Title",
        "Created",
        "Updated",
        "Action",
      ];
      this.getResults();
      this.initMainContentEditor();
    },
    methods: {
      //initiate the main content editor
      initMainContentEditor() {
        var _this = this;
        this.mainContentEditor = new Quill(this.$refs.mainContentEditor, {
          modules: {
            toolbar: [
                [
                  {
                    font: []
                  },
                  {
                    size: []
                  }
                ],
                ['bold', 'italic', 'underline', 'strike'],
                [
                  {
                    color: []
                  },
                  {
                    background: []
                  }
                ],
                [
                  {
                    script: 'super'
                  },
                  {
                    script: 'sub'
                  }
                ],
                [
                  {
                    header: '1'
                  },
                  {
                    header: '2'
                  },
                  'blockquote',
                  'code-block'
                ],
                [
                  {
                    list: 'ordered'
                  },
                  {
                    list: 'bullet'
                  },
                  {
                    indent: '-1'
                  },
                  {
                    indent: '+1'
                  }
                ],
                [
                  'direction',
                  {
                    align: []
                  }
                ],
                ['link', 'image', 'video', 'formula'],
                ['clean']
              ]
          },
          theme: "snow",
          // formats: ["bold", "underline", "header", "italic", "link", 'image', 'video'],
          placeholder: "Type something in here!",
        });
        //register the event handler
        this.mainContentEditor.on("text-change", function () {
          _this.mainContentEditorChanged();
        });
      },
      //this method is called when the editor changes its value
      mainContentEditorChanged() {
        // console.log("main content changed!", this.mainContentEditor);
        //   do somethign with it like assign it to mainContentEditorValue
        this.mainContentEditorValue = this.mainContentEditor.root.innerHTML;
      },
      async add() {
        (this.form.id = null),
        (this.form.title = ""),
        (this.form.desc = ""),
        (this.form.image = ""),
        (this.response = "");
        this.$refs.file.value = "";
        this.url= null
        this.isShow = "show"
        this.isDisplay = "block"
      },
      async getResults(page = 1) {
        await axios
          .get(process.env.VUE_APP_API_URL+"/blog?page=" + page, 
          {
            headers: {
              "Content-Type": "application/json",
              'Accept': "application/json",
              "Authorization": `Bearer ${this.token}`
            }
          })
          .then((response) => {
            this.paginationData = response.data.data;
          });
        this.list = this.paginationData.data;
      },
      uploadImage(e) {
          let img = e.target.files[0]
          this.form.image = img;
          this.url = URL.createObjectURL(img);
      },
      async submitForm() {
        this.form.desc = this.mainContentEditorValue
        this.disabled = true;
        if (this.form.title.length === 0) {
          this.titleError = "block"
        } else if (this.form.desc.length === 0) {
          this.descError = "block"
        } else {
          this.descError = "none";
          
          if (this.form.id) {
            await this.form.post(process.env.VUE_APP_API_URL+"/blog/update/" + this.form.id,
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              }
            )
            .then((response) => {
              if (response.data.status) {
                this.$toast.success(response.data.message);
                this.isShow = ""
                this.isDisplay = "none"
                this.disabled = false;
                this.getResults();
              } else {
                this.$toast.error(response.data.error?.[0]);
                this.disabled = false;
              }
            })
            .catch((error) => {
              this.$toast.error(error);
              this.disabled = false;
            });
          } else {
            await this.form.post(process.env.VUE_APP_API_URL+"/blog",
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              })
              .then((response) => {
                if (response.data.status) {
                  this.$toast.success(response.data.message);
                  this.isShow = ""
                  this.isDisplay = "none"
                  this.disabled = false;
                  this.getResults();
                } else {
                  this.$toast.error(response.data.error?.[0]);
                  this.disabled = false;
                }
              })
              .catch((error) => {
                this.$toast.error(error);
                this.disabled = false;
              });
          }
        }
      },
      deleteDes(id) {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ms-1",
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            axios
              .delete(process.env.VUE_APP_API_URL+"/blog/" + id,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.token}`
                  },
                }
              )
              .then(response => {
                this.getResults();
                if (response.data.status) {
                  this.$toast.success(response.data.message);
                } else {
                  this.$toast.error(response.data.error?.[0]);
                }
              })
              .catch((error) => {
                this.$toast.error(error);
              });
          }
        });
      },
      async edit(id) {
        this.titleError= "none"
        this.descError= "none"
        this.disabled= false
  
        const output = await axios.get(process.env.VUE_APP_API_URL+"/blog" +(id ? "/" + id : ""),
        {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
            },
        });
        this.form.id = output.data.data?.id;
        this.form.title = output.data.data?.title;
        this.form.image = output.data.data?.image;
        this.mainContentEditor.root.innerHTML = output.data.data.desc

        this.url = output.data.data?.image
  
        this.modalName = "Edit";
        this.isShow = "show"
        this.isDisplay = "block"
      },
      async searchData() {
        await axios
          .post(process.env.VUE_APP_API_URL+"/blog/search",
            {
              search: this.search,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
              },
            }
          )
          .then((response) => {
            if (response.data.status) {
              this.list = response.data.data.data;
              this.isShow = ""
              this.isDisplay = "none"
            } else {
              this.$toast.error(response.data.error?.[0]);
            }
          })
          .catch((error) => {
            this.$toast.error(error);
          });
      },
    },
  };
  </script>
  
  <style>
  .ck-content {
    min-height: 200px;
  }
  .ql-container, .ql-editor{
    min-height: inherit;
  }
  </style>